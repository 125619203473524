.padding{
    padding-top:1rem;
    padding-bottom: 2rem;
}

.padding-b{
    padding-bottom: 2rem;
}

.padding-t{
    padding-top:2rem;
}

#tsparticles canvas{
    width:100vw !important;
    height:100vh !important;
    background-image: url("");
    background-size: cover;
    background-repeat: no-repeat;
    background-position:50% 50%;
}

/* home page styles */


.hello-world-font{
    font-size:500%;
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    color:white;
    margin:0;
    text-shadow: 2px 2px 10px #000;
}


.name-font{
    font-family: 'Raleway', sans-serif;
    font-size:250%;
    font-weight: bold;
    color:white;
    text-shadow: 1px 1px 10px #000;
}

.short-intro-font{
    /*font-family: 'Roboto', sans-serif;*/
    font-family: 'Raleway', sans-serif;

    font-size:130%;
    color:black;
    margin-top:50px;
}

.button-block{
    position:relative;
    top:30%;
    left:10%;
    height:20%
}

.carousel-section{
    position:relative;
    margin-bottom:-20px;
}

.carousel-section img{
    width:100%;
    height:100%;
}

.slide{
    position:relative;
    text-align:center;
}

.slide-caption{
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    position: absolute;
    z-index: 2;
}

.jumbotron{
    border-radius:0;
    margin-top:0;
}

@media (max-width:768px){
    .slide-caption{
        top:50%;

    }
    .hello-world-font{
        font-size:350%;
        font-family: 'Raleway', sans-serif;
        font-weight: bold;
        color:white;
        margin:0;
    }
    .name-font{
        font-family: 'Raleway', sans-serif;
        font-size:140%;
        font-weight: bold;
        color:white;
    }
}

.info-about-me{
    position:relative;
    top:0;
}

.bg-particles{
    /* Full height */
    position:absolute;
}


/* header styles */
.navbar{
    padding:.8rem;
}

.header-banner{
    width:100%;
    height:75px;
    background-color:black;
}

.header-color{
    background-color:black;
}

.header-block{
    /*position: relative;
    left:55%;*/
}

.navbar-nav a:link{
    color:white;
    text-decoration: none;
}

.navbar-nav a:hover{
    color:#dbdbdb;
    text-decoration: none;
}

.header-font {
    text-decoration: none;
    display:inline-block;
    margin-right:30px;
    font-family: 'Raleway', sans-serif;
    font-size:17px;
    color:white;
}
.header-banner a:link {
    color: white;
}

/* visited link */
.header-banner a:visited {
    color: white;
}

.header-banner a:hover{
    color:white;
    text-decoration: none;
}

/* about me page styles */
.first-section{
    position:relative;
    width:100vw;
    height:750px;
    box-shadow: 0 1px 2px gray;
}

.about-me-block{
    position:relative;
    display:inline-block;
    left:0;
    top:0;
}

.about-me-header{
    font-family: 'Raleway', sans-serif;
    font-size:72px;
    font-weight: bold;
    margin:0;
}

.about-me-paragraph-text{
    font-family: 'Raleway', sans-serif;
    font-size:20px;
}

.image-block{
    position:relative;
    display:inline-block;
    top:0;
    left:0;
    width:100%;
    height:auto;
}

.grad_pic{
    height:auto;
    width:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.side-image-block{
    position:relative;
    display:inline-block;
    vertical-align:top;
    top:15%;
    left:0px;
    width:100px;
    height:500px;
}

.side-img-hovered{
    opacity:1;
    transition:0.4s;
    left:20px;
    width:250%;
    height:auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}


.side-img-unhovered{
    opacity:0.60;
    transition:0.4s;
    max-width:100%;
    height:auto;
    margin-bottom:5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.second-section{
    position:relative;
    top:-70px;
    background-color:#f2f2f2;
    width:100vw;
    height:1300px;
    box-shadow: 0 1px 2px gray;
}

.experience-block{
    position:relative;

}

.experience-text{
    font-family: 'Raleway', sans-serif;
    font-size:72px;
    font-weight: bold;
}

.uci-block{
    position:relative;
    left:0px;
    top:0px;
}

.education-header{
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    font-weight: bold;
    color:black;

    width:90%;
    border-bottom:solid 1px gray;
}

.sjsu-logo{
    position:relative;
    display: inline-block;
    top:0px;
    left:0px;
    width:30%;
    height:auto;
}

.uci-logo{
    position:relative;
    display: inline-block;
    top:0px;
    left:0px;
    width:30%;
    height:auto;
}

.lspire-logo{
    position:relative;
    display: inline-block;
    top:0px;
    margin-right:50px;
    width:120px;
    height:auto;
}

.uci-text{
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    font-weight: bold;
    color:black;
}

.lspire-text{
    display: inline-block;
    margin-top:0px;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    font-weight: bold;
    color:black;
}

.year-text{
    display: inline-block;
    margin-left:0px;
    margin-top:0px;
    text-align: right;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    color:black;
}

.relevant-course{
    margin-left:0px;
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    color:black;
}

.course-list{
    list-style-type:circle;
    margin-left:0px;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    color:black;
}

.bubble-block{
    position:relative;
    margin-left:0px;
    margin-top:0px;
}

.bubble-break{
    height:10px;
}

.ed-work-spacing{
    position:relative;
    height:75px;
    width:auto;
}

/*---PROJECT PAGE---*/
.project-section{
    position:relative;
    top:-70px;
    width:100vw;
    height:1300px;
}

.project-page-left-spacing{
    position:relative;
    top:75px;
    left:300px;
}

.project-block{
    position:relative;
    left:0px;
    top:-50px;
}

.project-info-right{
    position:relative;
    display:inline-block;
    left:700px;
}

.project-info{
    position:relative;
    display:inline-block;
}

.project-screenshot{
    position:relative;
    display:inline-block;
    vertical-align: top;
    margin-left:0px;
    width:100%;
    height:auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.project-title{
    display: inline-block;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    font-weight: bold;
    color:black;
}

.project-points{
    list-style-type:circle;
    margin-left:0px;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    color:black;
}

.project-bubble-block{
    position:relative;
    margin-left:0px;
    margin-top:40px;
}

.project-spacing{
    height:200px;
}

.other-projects-section{
    position:relative;
    top:100px;
    height:750px;
    width:100vw;
}

.other-proj-header{
    position:relative;
    font-family: 'Raleway', sans-serif;
    font-size:50px;
    font-weight: bold;
}

.horizontal-row{
    position:relative;
    width:1500px;
    height:400px;
}

.single-proj{
    position:relative;
    background-color:#f2f2f2;
    width:100%;
    height:100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.single-proj-title{
    position:relative;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    font-weight: bold;
    color:black;
}

.single-proj-text{
    position:relative;
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    color:black;
}

.single-proj-bblock{
    position:relative;
}

/* CONTACT ME PAGE */
.contact-text{
    font-family: 'Raleway', sans-serif;
    font-size:20px;
    color:black;
}

.social a{
    font-size:3.5em;
    padding-right:3rem;
}

.fa-linkedin {
    color: #0976b4;
}

.fa-github{
    color:#333;
}

.fa-spotify {
    color: #1ED760;
}

.fa-twitch{
    color:#6441a5;
}

.fa-linkedin:hover,
.fa-github:hover,
.fa-spotify:hover,
.fa-twitch:hover{
    color:#dbdbdb;
}

/* FUN PAGE */
.fun-text{
    font-family: 'Raleway', sans-serif;
    font-size:50px;
}

